:local(.loading) {
  color: #033a11;
  font-size: 3rem !important;
  animation: spinnow 1s infinite linear;
}

@keyframes spinnow {
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
  }
}

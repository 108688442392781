.whead {
  border-bottom: 1px solid #999;
  padding: 10px 15px;
  position: relative;
}

.whead::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 33%;
  background: #0080FE;
  transition: all .3s ease-in-out;
}

.whead img {
  width: 140px;
  vertical-align: middle;
}

.upGr {
  width: calc(50% - 20px);
  /* float: left; */
  display: inline-block;
  margin: 0 0 30px 0;
  vertical-align: top;
}

.btnUp {
  background: #f2f2f2;
  border: 2px dashed #ccc;
  color: #B5B5B5;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  width: 100%;
}

.btnUp:hover {
  background: #e2e2e2;
}

.upGr input[type=file]{
  display: none;
}

.ipl {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
}

.ipl input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #9e9e9e;
  padding: 8px 0;
  font-size: 14px;
  line-height: 14px;
  outline: none;
}

.ipl .plh {
  position: absolute;
  top: 8px;
  left: 0;
  transition: all .3s ease-in-out;
  color: #888;
}

.ipl .lnb {
  position: absolute;
  display: block;
  width: 0;
  left: 50%;
  height: 2px;
  background: #0080FE;
  bottom: 0;
  transform: translateX(-50%);
  transition: all .1s ease-in-out;
}

.ipl input:focus ~ .plh {
  color: #0080FE;
}

.ipl input:focus ~ .plh,
.ipl input:not(:focus):valid ~ .plh {
  top: -10px;
  font-size: 10px;
}

.ipl input:focus ~ .lnb {
  width: 100%;
}

.phPrev {
  width: calc(50% - 22px);
  margin: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* float: left; */
  display: inline-block;
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
}

.phPrev button {
  width: 25px;
  height: 25px;
  position: absolute;
  background: #666;
  border: none;
  right: -5px;
  top: -5px;
  border-radius: 50%;
  padding: 0;
  outline: none;
  cursor: pointer;
}

.phPrev button span {
  width: 12px;
  height: 2px;
  background: #fff;
  border: none;
  left: 50%;
  top: 50%;
  display: block;
  position: absolute;
}

.phPrev button span:first-of-type {
  transform: translate(-50%, -50%) rotate(45deg);
}

.phPrev button span:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .phPrev:nth-of-type(2n+1),
  .upGr:nth-of-type(2n+1) {
    margin-right: 18px;
  }

  .phPrev:nth-of-type(2n),
  .upGr:nth-of-type(2n) {
    margin-right: 0;
    margin-left: 18px;
  }
}

@media screen and (min-width:768px) {
  .upGr {
    width: calc(33.3333% - 22px);
  }

  .phPrev {
    width: calc(33.3333% - 22px);
  }

  .phPrev:nth-of-type(3n+1),
  .upGr:nth-of-type(3n+1) {
    margin-right: 28px;
  }

  .phPrev:nth-of-type(3n),
  .upGr:nth-of-type(3n) {
    margin-right: 0;
    margin-left: 28px;
  }
}

.rotateContainer {
  position: absolute;
  z-index: 1;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.561);
}

.rotateContainer > span {
  padding: 0px 8px;
  cursor: pointer;
}

.rotateContainer > span > svg {
  color: white;
}
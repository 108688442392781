.mentions {
  margin: 1em 0;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  border-radius: 4px;
}
.mentions--singleLine .mentions__higlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 5px;
  padding-right: 40px;
  border: 2px inset;
  font-family: Arial, Helvetica, sans-serif !important;
}

.mentions--multiLine .mentions__control {
  font-family: monospace;
  font-size: 14pt;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 4px;
  transition: border-color 0.1s linear;
}

.mentions--multiLine .mentions__control:focus-within {
  border-color: #009be5;
}

.mentions--multiLine .mentions__highlighter {
  padding: 9px;
}
.mentions--multiLine .mentions__input {
  padding: 10.5px 14px;
  padding-right: 40px;
  min-height: unset !important;
  outline: 0;
  border: 0;
  font-size: 15px !important;
  line-height: 18px;
  letter-spacing: .2px !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  background-color: #cee4e5;
}